@charset "UTF-8";
.js-loaded {
    .top {
        &-mv {
            &-images {
                animation-name: mv;
                animation-duration: 1s;
                animation-delay: 0s;
                animation-iteration-count: 1;
                animation-fill-mode: forwards;
            }

            &-logo {
                animation-name: mv;
                animation-duration: 0.5s;
                animation-delay: 1.5s;
                animation-iteration-count: 1;
                animation-fill-mode: forwards;

            }

            &-copy {
                animation-name: mv;
                animation-duration: 0.5s;
                animation-delay: 2.2s;
                animation-iteration-count: 1;
                animation-fill-mode: forwards;
            }
        }
    }
}

.top {

    @keyframes mv {
        0% {
            opacity: 0;
        }
        100% {
            opacity: 1;
        }
    }


    &-mv {
        position: relative;
        height: 100vh;
 

        &-images {
            width: 100%;
            height: 100vh;
            opacity: 0;
        }

        &-image {
            width: 100%;
            height: 100vh;
            background-position: center center;
            background-repeat: no-repeat;
            background-size: cover;
        }

        &-text {
            position: absolute;
            max-width: auto;
            height: 151px;
            width: 272.5px;
            max-height: 80%;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            margin: auto;
            text-align: center;
        }

        &-logo {
            max-width: 272.5px;
            margin-bottom: 48px;
            opacity: 0;

            @media screen and (max-width: 640px) {
                max-width: 80%;
            }
        }

        &-copy {
            max-width: 227.5px;
            opacity: 0;
        }
    }

    &-nav {
        @extend .en;
        margin: 0 auto;
        padding: 100px 0 0;
        position: relative;
        letter-spacing: 0.1em;

        @include view-at(sp) {
            display: none;
        }
    }

    &-gnav {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 40px;

        > li {
            margin: 0 25px;
            font-size: rem(22px);
            line-height: rem(30px);
            position: relative;

            a {
                color: $color-text;
                text-decoration: none;
            }
        }

        &-line {
            width: 1px;
            height: 20px;
            background-color: $color-text;
        }

        &-submenu {
            display: block;
            background-color: rgba(255,255,255,0.7);
            position: absolute;
            left: 0px;
            top: 29px;
            z-index: 10;
            padding: 13px 30px 14px 20px;
            transform: scaleY(0);
            // transition-duration: 0.3s;
            transform-origin: top;

            &.js-active {
                transform: scaleY(1);
            }
            
            li {
                font-size: rem(13px);
                line-height: rem(33px);
                white-space: nowrap;

                a {
                    color: $color-text;
                    text-decoration: none;
                }
            }
        }
    }

    &-subnav {
        display: flex;
        justify-content: center;

        li {
            padding: 0 15px;
            font-size: rem(13px);
            line-height: rem(17px);

            a {
                color: $color-text;
                text-decoration: none;
            }
        }
    }

    &-snsnav {
        position: absolute;
        top: 20px;
        right: 20px;

        li {
            margin-bottom: 10px;

            a {
                width: 20px;
                display: block;
                text-decoration: none;
            }
        }
    }

    &-banners {
        margin-top: 100px;

        &-left,
        &-right {
            display: block;
            opacity: 0;
            transform: translateY(20%);
            transition-duration: 1s;

            &.js-active {
                opacity: 1;
                transform: translateY(0);
            }
        }

        &-left {
            transition-delay: 0.5s;

            @include view-at(sp) {
                margin-bottom: 20px;
            }
        }

        &-right {
            transition-delay: 0.75s;
        }
    }

    &-separator {
        position: relative;
        height: 400px;
 
        @include view-at(sp) {
        height: 281px;
        }

        & div {
            height: 100%;
        }

        &-images {
            width: 100%;
            height: 100%;
            position: relative;
        }

        @keyframes separator {
            0% {
                opacity: 0;
                z-index: 3;
            }
            8.333% {
                opacity: 1;
                z-index: 3;
            }
            41.666% {
                opacity: 1;
                z-index: 2;
            }
            50% {
                opacity: 0;
                z-index: 2;
            }
            100% {
                opacity: 0;
                z-index: 1;
            }
        }

        &-image {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            margin: auto;
            opacity: 0;
            background-position: center 0;
            background-repeat: no-repeat;
            background-size: cover;
            animation-name: separator;
            animation-duration: 12s;
            animation-iteration-count: infinite;
            transition: all 0.45s cubic-bezier(.22,.61,.36,1);

            &--1 {
                animation-delay: 0s;
            }

            &--2 {
                animation-delay: 4s;
            }

            &--3 {
                animation-delay: 8s;
            }
        }
    }

    &-access {
        &-parking {
            width: 15px;
            height: 15px;
            line-height: 31px;
            display: inline-block;
            vertical-align: top;
            text-align: center;
            font-weight: $bold;
            margin-right: 0.2em;
            letter-spacing: 0;

            @include view-at(pc) {
                width: 17px;
                height: 17px;
                line-height: 37px;
            }
        }
    }

    &-map {
        height: 400px;
    }
}
