.floorguide {
  &-map {
    display: flex;
    justify-content: space-between;
    margin-bottom: 120px;

    &-plan {
      max-width: 530px;
    }

    &-shops {
      margin-left: 60px;
      width: 390px;
    }

    &-floor {
      @extend .en;
      font-size: rem(34px);
      line-height: rem(45px);
      letter-spacing: 0.2em;
      padding-bottom: 10px;
      border-bottom: 2px solid $color-text;
      margin-bottom: 30px;
    }

    &-list {
      list-style: none;

      li {
        counter-increment: section;
        line-height: 2em;

        &.nonumber {
          &::before {
            content: '';
            display: none;
          }
        }

        &.hidenumber {
          padding-left: 2.15em;
          
          &::before {
            content: '';
            display: none;
          }
        }

        &::before {
          content: counter(section, decimal-leading-zero);
          @extend .en;
          letter-spacing: 0.05em;
          margin-right: 15px;
        }

        a {
          @extend .en;
          letter-spacing: 0.05em;
        }
      }
    }
  }

  &-h2 {
    @include view-at(sp) {
      &::after {
        display: none;
      }
    }
  }
}