/* --------------------------
Gナビ
-------------------------- */

.p-gnav {
  background-color: $color-blue-light;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  width: 300px;
  height: 100%;
  z-index: 200000;
  padding-top: 35px;
  transition-duration: 0.3s;
  transform: translateX(300px);
  overflow-y: auto;

  &.visible {
    transform: translateX(0);
  }

  &-close {
    margin: 0 0 0 35px;
    width: 17.5px;
    display: block;
  }

  &-list {
    margin: 0 0 30px;
    padding-left: 90px;

    > li {
      @extend .en;

      font-size: rem(24px);
      line-height: rem(32px);
      letter-spacing: 0.1em;
      margin: 17px 0;

      a {
        text-decoration: none;
        color: $color-text;
      }
    }

    &-sub {
      padding-left: 0px;

      &.js-visible {
        >li {
          margin-top: 0;
          transition-delay: 0s;

          &:last-child {
            padding-bottom: 10px;
          }

          a {
            opacity: 1;
            transition-delay: 0.3s;
            visibility: visible;
            text-decoration: none;
          }
        }
      }

      > li {
        @extend .en;

        font-size: rem(13px);
        line-height: rem(17px);
        letter-spacing: 0.1em;
        padding: 20px 0 0;
        margin-top: -37px;
        transition-property: all;
        transition-duration: 0.3s;
        transition-delay: 0.3s;

        a {
          visibility: hidden;
          color: $color-text;
          opacity: 0;
          transition-property: all;
          transition-duration: 0.3s;
          transition-delay: 0s;
          text-decoration: none;
        }
      }
    }
  }

  &-sublist {
    margin: 30px 0;
    padding-left: 90px;

    > li {
      @extend .en;

      font-size: rem(13px);
      line-height: rem(17px);
      letter-spacing: 0.1em;
      margin: 10px 0;

      a {
        color: $color-text;
        text-decoration: none;
      }
    }
  }

  &-snslist {
    @extend .c-list;
    @extend .c-list--horizontal;
    margin-top: 30px;
    padding-left: 90px;
    padding-bottom: 20px;
    align-items: baseline;

    @include view-at(sp) {
      display: flex;
    }

    >li {
      margin-left: 9px;

      @include view-at(sp) {
        margin-bottom: 0;
      }

      a {
        width: 20px;
        display: block;
        text-decoration: none;
      }
    }
  }
}
