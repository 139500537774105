/* --------------------------
フッター
-------------------------- */

.p-fnav {

  &-list {
    >li {
      margin-bottom: 20px;

      a {
        color: $color-white;
        text-decoration: none;
        font-size: 0.86rem;
        font-weight: $medium;
        letter-spacing: 0.08em;

        &:hover {
          color: $color-white;
          opacity: 0.6;
        }
      }
    }

    &-sub {
      >li {
        margin-top: 15px;
        color: $color-white;
        text-decoration: none;
        font-size: 0.8rem;
        font-weight: $regular;
        letter-spacing: 0.08em;
        
        &::before {
          content: '-';
        }
      }

      &--horizontal {
        display: flex;
        flex-wrap: wrap;
        max-width: 355px;

        >li {
          &:nth-child(2n) {
            width: 115px;
          }
          &:nth-child(2n-1) {
            width: 240px;
          }
        }
      }
    }
  }
}
