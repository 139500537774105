/* --------------------------
枠線
-------------------------- */

.u-border {
  &-bottom {
    border-bottom: 3px solid $color-main-light;
    padding-bottom: 10px;
  }

  &-top {
    border-top: 3px solid $color-main-light;
    padding-top: 10px;
  }

  &-right {
    border-right: 3px solid $color-main-light;
    padding-right: 10px;
  }

  &-left {
    border-left: 3px solid $color-main-light;
    padding-left: 10px;
  }

  &--white {
    border-color: $color-white;
  }
}
