.news {
  &-categolies {
    display: flex;
    justify-content: center;
    margin-bottom: 60px;
    flex-wrap: wrap;

    @include view-at(sp) {
      justify-content: center;
    }

    &-wrapper {
      overflow-x: hidden;
    }

    li {
      @extend .en;
      letter-spacing: 0.2em;
      font-size: rem(14px);
      line-height: rem(19px);

      @include view-at(sp) {
        margin-bottom: 1em;
      }

      &::before {
        content: '/';
        margin-left: 15px;
        margin-right: 15px;
      }

      &:first-child {
        &::before {
          display: none;
        }
      }

      a {
        color: $color-text;
        text-decoration: none;
      }
    }
  }

  &-category {
    @extend .en;
    font-size: rem(14px);
    line-height: rem(19px);
    letter-spacing: 0.2em;
  }

  &-date {
    font-size: rem(13px);
    line-height: rem(25.2px);
    letter-spacing: 0.05em;

    &::before {
      content: '';
      width: 1px;
      height: 20px;
      background-color: $color-text;
      margin: 0 20px;
      display: inline-block;
      vertical-align: middle;
    }
  }

  &-title {
    font-size: rem(25px);
    line-height: rem(40px);
    letter-spacing: 0.05em;
    font-weight: $bold;
    margin: 30px 0 20px;

    @include view-at(sp) {
      font-size: rem(24px, sp);
      line-height: rem(40px, sp);
      margin-bottom: 20px;
    }
  }

  &-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;

    @include view-at(sp) {
      display: block;
    }
  }

  &-sns {
    display: flex;

    @include view-at(sp) {
      margin-top: 20px;
    }

    li  {
      margin-left: 10px;

      &:first-child {
        margin-left: 0;
      }
    }

    &-link {
      display: block;
      border-radius: 50%;
      padding: 7px 5px;
      width: 32px;
      height: 32px;
      text-align: center;
      font-size: 16px;
      text-decoration: none;

      &--facebook {
        // border: 1px solid #666;
        // color: #666;
        transition: all .3s;
        border: 1px solid #1877F2;
        color: #1877F2;

        &:hover {
          background-color: #1877F2;
          border: 1px solid #1877F2;
          color: #fff;
        }
      }

      &--twitter {
        // border: 1px solid #666;
        // color: #666;
        transition: all .3s;
        border: 1px solid #1DA1F2;
        color: #1DA1F2;

        &:hover {
          background-color: #1DA1F2;
          border: 1px solid #1DA1F2;
          color: #fff;
        }
      }

      &--line {
        // border: 1px solid #666;
        // color: #666;
        transition: all .3s;
        border: 1px solid #00b900;
        color: #00b900;
        font-size: 21px;
        padding: 6px 4px;

        &:hover {
          background-color: #00b900;
          border: 1px solid #00b900;
          color: #fff;
        }
      }
    }
  }
}