/* --------------------------
ヘッダー
-------------------------- */

.p-header {
  position: relative;
  z-index: 10000;

  &-logo {
    margin: 50px 0 0 50px;

    @include view-at(sp) {
      margin: 30px 0 0 30px;
    }

    a {
      display: inline-block;
      line-height: 1;
      font-size: 0;

      img {
        width: 200px;
        height: auto;

        @include view-at(sp) {
          width: 136px;
        }
      }
    }
  }
}
