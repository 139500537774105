
/* --------------------------
color
-------------------------- */

$color-text: #111;
$color-text-light: #999;

$color-main: #7B7462;
$color-main-light: #ACA79A;
$color-main-dark: #5A5445;
$color-accent: #4B6F62;

$color-accent-corporate: #4B6F62;
$color-accent-kaigo: #65534D;
$color-accent-hoiku: #A75F6F;
$color-accent-tojinkai: #487283;


$color-red: #ff0000;
$color-red-dark: #cc0000;
$color-red-light: #f8ebed;
$color-blue: #0000ff;
$color-blue-dark: #0b2b75;
$color-blue-light: #BFD4DB;
$color-blue-snow: #E8EEEE;
$color-orange: #f19149;
$color-green: #82cf4f;
$color-green-light: #E8EEE7; 
$color-green-dark: #4e8826;
$color-white: #fff;
$color-black: #111;

$color-gray: #999;
$color-gray-light: #eee;
$color-gray-dark: #666;
$color-gray-snow: #F5F5F5;


/* --------------------------
spacing
-------------------------- */
$space: 120px, 80px, 30px, 20px;
$space-tab: 90px, 60px, 30px, 15px;
$space-sp: 90px, 60px, 20px, 10px;

/* --------------------------
font
-------------------------- */
$font-size: 3.33rem, 2rem, 1.6rem, 1.2rem, 1rem, 0.875rem;
$line-height: 1.5, 1.5, 1.5, 1.5, 1.5, 1.5;

$font-size-sp: 3.33rem, 2rem, 1.6rem, 1.2rem, 1rem, 0.875rem;
$line-height-sp: 1.5, 1.5, 1.5, 1.5, 1.5, 1.5;

$bold: 700;
$medium: 500;
$regular: 400;
$light: 300;
$normal: 400;

/* --------------------------
contents width  
-------------------------- */
$contents-width: 1280px;
$contents-middle-width: 1000px;
$contents-narrow-width: 800px;
$contents-padding: 60px;
$contents-padding-sp: 30px;

/* --------------------------
z-index order
-------------------------- */
$z: (
  contents,
  arrow,
  gnav,
  leftbar,
  menu,
  header,
  footer,
  modal
);
