.about {
  &-separator {

    &-image {
      height: 550px;

      @include view-at(tab) {
        height: 400px;
      }

      @include view-at(sp) {
        height: 281px;
      }
      background-position: center calc(50% - 101.55px);
      background-repeat: no-repeat;
      background-size: cover;
      transition: all 0.45s cubic-bezier(.22,.61,.36,1);

      @include view-at(sp) {
        background-position: center center;
      }
    }
  }

  &-concept {
    margin: 85px 0;

    &-container {
      max-width: 720px;
      padding: 0 60px;
      margin: 0 auto;

      @include view-at(sp) {
        padding: 0 30px;
      }
    }

    &-title {
      @extend .en;
      font-size: rem(24px);
      letter-spacing: 0.2em;
      margin-bottom: 70px;
      text-align: center;
    }

    &-copy {
      font-size: rem(25px);
      line-height: rem(43px);
      margin-bottom: 40px;
      letter-spacing: 0.05em;

      @include view-at(sp) {
        text-align: center;
      }
    }
  }

  &-contents {
    &-body {
      max-width: 470px;

      &--left {
        margin: 35px 30px 80px auto;

        @include view-at(sp) {
          margin: 35px 30px 80px;
        }
      }

      &--right {
        margin: 35px auto 80px 90px;

        @include view-at(sp) {
          margin: 35px 30px 80px;
        }
      }
    }
  }

  &-access {
    &-map {
      width: 100%;
      height: 600px;

      @include view-at(sp) {
        height: 300px;
      }
    }
  }

  &-contributors {
    &-title {
      @extend .c-h3;
      text-align: center;
      margin-bottom: 80px;
    }

    &-role {
      @extend .en;
      font-size: rem(14px);
      line-height: rem(18px);
      letter-spacing: 0.1em;
      margin-bottom: 20px;
    }

    &-name {
      @extend .en;
      font-size: rem(20px);
      line-height: rem(27px);
      letter-spacing: 0.1em;
      color: $color-gray;
    }
  }
}