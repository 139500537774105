
.p-news {
  position: relative;
  display: block;
  text-decoration: none;

  &-title {
    line-height: 2em;
    letter-spacing: 0.05em;
    margin-top: 10px;
    margin-bottom: 5px;
    color: $color-text;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;

    @include view-at(pc) {
      font-size: rem(13px);
    }
  }

  &-date {
    color: $color-gray;

    @include view-at(pc) {
      font-size: rem(13px);
    }
  }

  &-category {
    @extend .en;
    position: absolute;
    top: 20px;
    right: 0px;
    width: 0;
    overflow: visible;
    transform: rotate(90deg);
    transform-origin: right center;
    font-size: rem(14px);
    letter-spacing: 0.2em;
    color: $color-text;

    @include view-at(sp) {
      top: 10px;
    }
  }
}