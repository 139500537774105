/* --------------------------
見出し
-------------------------- */

.c-h1 {
  @extend .en;
  color: $color-text;
  font-size: rem(34px);
  line-height: rem(45px);
  margin-bottom: 80px;
  text-align: center;
  letter-spacing: 0.2em;

  @include view-at(sp) {
    font-size: rem(34px, sp);
    line-height: rem(45px, sp);
    margin-bottom: 60px;
  }

  &::after {
    content: '';
    display: block;
    width: 75px;
    height: 2px;
    margin: 21px auto 0;
    background-color: $color-text;
    transform: scaleX(0);
    transition-duration: 0.3s;
    transition-delay: 0.5s;
    transform-origin: left;
  }

  &.js-active {
    &::after {
      transform: scaleX(1);
    }
  }
}

.c-h2 {
  @extend .c-h1;
}

.c-h3 {
  @extend .en;
  font-size: rem(25px);
  line-height: rem(33px);
  letter-spacing: 0.2em;
  margin-bottom: 1em;

  @include view-at(sp) {
    font-size: rem(25px, sp);
    line-height: rem(33px, sp);
  }
}

.c-h4 {
  font-size: rem(20px);
  line-height: rem(34px);
  letter-spacing: 0.05em;
  margin-bottom: 30px;

  @include view-at(sp) {
    font-size: rem(20px, sp);
    line-height: rem(34px, sp);
    margin-bottom: 20px;
  }
}

.c-h5 {
  font-size: nth($font-size, 5);
  line-height: nth($line-height, 5);
  letter-spacing: 0.08em;
  font-weight: $medium;
  margin-bottom: 5px;

  @include view-at(sp) {
    font-size: nth($font-size-sp, 5);
    line-height: nth($line-height-sp, 5);
  }
}

.c-h6 {
  font-size: nth($font-size, 6);
  line-height: nth($line-height, 6);
  letter-spacing: 0.08em;
  font-weight: $medium;
  margin-bottom: 5px;

  @include view-at(sp) {
    font-size: nth($font-size-sp, 6);
    line-height: nth($line-height-sp, 6);
  }
}

.c-h--center-line {
  @include center-line();
  margin-bottom: 5px;
}