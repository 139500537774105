/* --------------------------
パンくずナビ
-------------------------- */

.c-breadcrumb {
  background-color: $color-main-dark;
  &-list {
    list-style: none;
    margin: 0;
    padding: 10px 0;
    display: flex;

    li {
      color: $color-white;
      font-size: nth($list: $font-size, $n: 6);

      &::after {
        content: '/';
        padding: 0 0.5em;
      }
      
      &:last-child {
        &::after {
          content: '';
        }
      }

      a {
        color: $color-white;
      }
    }
  }
}
