/* --------------------------
ボタン
-------------------------- */

.c-button {
  display: inline-block;
  width: auto;
  border: 1px solid $color-accent;
  padding: 15px 13px;
  cursor: pointer;
  text-decoration: none;
  text-align: center;
  color: $color-white;
  background-color: $color-accent;
  border-radius: 4px;
  position: relative;
  line-height: 1;
  transition: color 0.1s, background-color 0.1s;

  &:hover {
    color: $color-accent;
    background-color: $color-white;
    text-decoration: none;
    opacity: 1;
  }

  &--external {
    padding: 15px 50px 15px 35px;
    &::after {
      position: absolute;
      top: 50%;
      right: 15px;
      margin-top: -7px;
      display: inline-block;
      font-family: 'Font Awesome 5 Pro';
      content: '\f360';
      font-weight: 900;
    }
  }

  &--arrow {
    padding: 15px 50px 15px 35px;
    &::after {
      position: absolute;
      top: 50%;
      right: 15px;
      margin-top: -7px;
      display: inline-block;
      font-family: 'Font Awesome 5 Pro';
      content: '\f105';
      font-weight: 900;
    }
  }

  &--internal {
    padding: 15px 50px 15px 35px;
    &::after {
      position: absolute;
      top: 50%;
      right: 15px;
      margin-top: -7px;
      display: inline-block;
      font-family: 'Font Awesome 5 Pro';
      content: '\f105';
      font-weight: 900;
    }
  }

  &--primary {
    background-color: $color-accent;
    border: 0;
    color: $color-white;

    @include view-at(sp) {
      padding: 15px;
      width: 100%;
    }
  }

  &--action {
    background-color: $color-accent;
    color: $color-white;
    font-size: nth($list: $font-size, $n: 3);
    padding: 20px 45px;
    min-width: 350px;
    letter-spacing: 0.1em;

    @include view-at(sp) {
      padding: 15px;
      width: 100%;
    }
    &::after {
      margin-top: -10px;
    }
  }

  &--disabled {
    pointer-events: none;
    background-color: $color-gray-light;
    border-color: $color-gray-light;
    color: $color-white;
  }

  &--large {
    min-width: 350px;

    @include view-at(sp) {
      min-width: auto;
      width: 100%;
    }
  }

  &--small {
    min-width: 255px;

    @include view-at(sp) {
      min-width: auto;
    }
  }
}
