/* --------------------------
リンク
-------------------------- */

.c-link {
  @extend .en;
  display: inline-block;
  letter-spacing: 0.1em;
  color: $color-text;
  line-height: 1em;
  text-decoration: none;

  &::after {
    content: '';
    display: inline-block;
    width: 100%;
    height: 1px;
    margin: 10px auto 0;
    background-color: $color-text;
    transform: scaleX(0);
    transition-duration: 0.3s;
    transition-delay: 0.5s;
    transform-origin: left;
  }

  &.js-active {
    &::after {
      transform: scaleX(1);
    }
  }

  &--block {
    display: block;
    text-decoration: none;

    img {
      display: block;
    }
  }

  &--none {
    text-decoration: none;
  }

  &--toggle {
    &::after {
      display: inline-block;
      font-family: 'Font Awesome 5 Pro';
      content: '\f107';
      font-weight: 400;
      margin-left: 10px;
    }

    &[aria-expanded=true] {
      &::after {
        transform: rotate3d(1,0,0,180deg);
      }
    }
  }

  &--pdf {
    &::after {
      display: inline-block;
      font-family: 'Font Awesome 5 Pro';
      content: '\f1c1';
      font-weight: 900;
      margin-left: 10px;
    }
  }

  &--external {
    &::after {
      display: inline-block;
      font-family: 'Font Awesome 5 Pro';
      content: '\f360';
      font-weight: 900;
      margin-left: 10px;
    }
  }
}
