/* --------------------------
ハンバーガーメニューアイコン
-------------------------- */

.c-menu {
  position: fixed;
  top: 40px;
  right: 40px;
  z-index: 10000;
  width: 81px;

  @include view-at(sp) {
    top: 30px;
    right: 30px;
    width: 81px;
  }
}
