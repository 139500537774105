@import "../components/form";

input[type="submit"],
input[type="button"] {
  -webkit-box-sizing: content-box;
  -webkit-appearance: button;
  appearance: button;
  box-sizing: border-box;
    &::-webkit-search-decoration {
      display: none;
    }
    &::focus {
      outline-offset: -2px;
    }
}
.mwform-checkbox-field {
  display: inline-block;
  margin-right: 0;
}

.mwform-checkbox-field-text {
  @extend .c-form-checkbox-span;
}

.mwform-checkbox-field {
  margin-top: 10px;
}

.mwform-radio-field-text {
  @extend .c-form-radio-span;
  line-height: 1.8;
  margin-right: 20px;
}

.mw_wp_form .horizontal-item + .horizontal-item {
  margin-left: 0;
}

.error {
  margin: 5px 0 10px;
}
