
.wp-pagenavi{
  text-align: center;
  margin-top: 50px;
  .pages{
    display: none;
  }
  span, a{
    /*数字部分の共通CSS　大きさなど*/
    @extend .en;
    font-size: rem(14px);
    letter-spacing: 0.2em;
    line-height: rem(19px);
    display: inline-block;
    margin: 0 7.5px;
    text-decoration: none;
    color: $color-text;

    &.current, &:hover{
      /*現在のページ*/
      color: $color-gray;
    }
  }
}
