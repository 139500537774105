/* --------------------------
フッター
-------------------------- */

.p-footer {
  background-color: $color-blue-light;
  color: $color-gray-dark;
  padding: 60px 0;
  font-size: rem(14px);

  @include view-at(sp) {
    padding: 30px 0;
  }

  &-container {
    @include view-at(pc) {
      padding: 0 $contents-padding;
    }

    @include view-at(sp) {
      padding: 0 $contents-padding-sp;
    }
  }

  &-link {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-bottom: 245px;

    @include view-at(tab) {
      display: block;
    }

    @include view-at(sp) {
      display: flex;
      margin-bottom: 120px;
      align-items: flex-start;
    }

    a {
      color: $color-gray-dark;
      text-decoration: none;
    }

    &-list {
      @extend .c-list;
      @extend .c-list--horizontal;
      @extend .en;
      letter-spacing: 0.1em;

      li {
        margin-left: 40px;

        @include view-at(sp) {
          margin-left: 0;
        }
      }
    }

    &-snslist {
      @extend .c-list;
      @extend .c-list--horizontal;

      @include view-at(tab) {
        margin-top: 20px;
      }

      @include view-at(sp) {
        display: flex;
        margin-top: 0px;
      }

      >li {
        margin-left: 9px;

        @include view-at(sp) {
          margin-bottom: 0;
          margin-left: 20px;
        }

        a {
          width: 20px;
          display: block;
          text-decoration: none;
        }
      }
    }
  }

  &-copyright {
    @extend .en;
    letter-spacing: 0.05em;

    a {
      text-decoration: none;
    }
  }
}
