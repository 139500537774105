/* --------------------------
ラベル
-------------------------- */

.c-label {
  display: inline-block;
  padding: 3px;
  text-align: center;
  width: 75px;
  border: 2px solid $color-gray;
  border-radius: 4px;
  font-size: rem(12px);
  line-height: 1;
  vertical-align: bottom;

  &--blog {
    border-color: $color-orange;
  }

  &--important {
    border-color: $color-red;
  }

  &--organized {
    border-color: $color-blue;
  }

  &--news {
    border-color: $color-green;
  }

  &--large {
    padding: 6px 12px;
    font-size: nth($list: $font-size, $n: 4);
  }

  &--small {
    padding: 5px 8px;
    font-size: nth($list: $font-size, $n: 6);
  }
}
