
.editor-writing-flow__click-redirect {
  margin-bottom: 0;
}

.editor-writing-flow,
.entry-content {
  h2 {
    font-size: rem(22px);
    line-height: rem(37px);
    letter-spacing: 0.05em;
    margin-top: 30px;
    margin-bottom: 30px;
    font-weight: bold;

    @include view-at(sp) {
      font-size: rem(22px, sp);
      line-height: rem(37px, sp);
    }
  }
  h3 {
    @extend .c-h3;
  }
  h4 {
    @extend .c-h4;
  }
  h5 {
    @extend .c-h5;
  }
  h6 {
    @extend .c-h6;
  }

  ul {
    @extend .c-list--point;
  }

  ol {
    @extend .c-list--order;
  }
}

.wp-block {
  max-width: 100%;
}

.wp-block-image,
.wp-block-columns,
.wp-block-media-text,
.wp-block-table,
.wp-block-cover,
.wp-block-quote,
.wp-block-button {
  margin: 40px 0;
}

.wp-block-separator {
  margin: 80px 0;
  border: 0;
  border-top: 1px solid #666;
}
.wp-block-quote {
  padding: 20px;
  background-color: $color-gray-snow;
}

.wp-block-image {
  &::after {
    content: "";
    clear: both;
    font-size: 0;
    height: 0;
    display: block;
    visibility: hidden;
  }

  figcaption {
    @include view-at(sp) {
      text-align: left;
      line-height: 1.5;
    }
  }
}

.wp-block-table {
  &.aligncenter,
  &.alignleft,
  &.alignright {
    width: 100%;
  }
}

@include view-at(tab) {
  .wp-block-columns {
    flex-wrap: nowrap;
  }
}

@include view-at(sp) {
  .wp-block-column + .wp-block-column {
    margin-top: nth($space, 2);
  }
}