/* --------------------------
リスト
-------------------------- */

.c-list{
  >li {
    line-height: 2em;
    position: relative;

    &:last-child {
      margin-bottom: 0;
    }
  }
  &--point {
    >li {
      padding-left: 10px;
      position: relative;
      &::before {
        content: '';
        width: 3px;
        height: 3px;
        border-radius: 1.5px;
        display: inline-block;
        background-color: $color-text;
        position: absolute;
        top: 11px;
        left: 0;
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &--link {
    > li {
      margin-bottom: 5px;
      position: relative;
      color: $color-green-dark;

      &::before {
        content: '＞';
        display: inline-block;
        margin-right: 3px;
      }
    }
  }

  &--horizontal {
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    @include view-at(sp) {
      display: block;
    }

    li {
      margin-left: 30px;
      margin-bottom: 0;

      @include view-at(sp) {
        margin-left: 0;
        margin-bottom: 20px;
      }

      &:first-child {
        margin-left: 0;
      }
    }
  }

  &--border {
    li {
      background-position: left 16px;
      padding: 14px;
      border-bottom: 1px dashed $color-main-light;
      overflow-x: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      width: 100%;
      line-height: 1;

      &:last-child {
        border-bottom: 0;
      }
      a {
        color: $color-black;
        text-decoration: none;

        &:hover {
          color: $color-blue;
        }
      }
    }
  }

  &--order {
    padding-left: 20px;
    margin-bottom: 10px;
    line-height: 2em;

  }

  &--note {
    li {
      line-height: 2em;
      &::before {
        content: '※';
        margin-right: 3px;
      }
    }
  }

  &--circle {
    li {
      padding-left: 20px;
      margin-bottom: 10px;
      line-height: 1.5;
      position: relative;

      &::before {
        content: '';
        width: 12px;
        height: 12px;
        border-radius: 6px;
        display: inline-block;
        background-color: $color-main-light;
        position: absolute;
        top: 6px;
        left: 0;
      }
    }
  }
}
