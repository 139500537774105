html {
  font-size: 16px;

  @include view-at(sp) {
    font-size: 15px;
  }
}

body {
  font-family: "游ゴシック体", YuGothic, "Yu Gothic M", "游ゴシック Medium", "Yu Gothic Medium", "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN W3", HiraKakuProN-W3, "ヒラギノ角ゴ ProN", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, sans-serif;
  font-weight: 300;
  color: $color-text;
  background-color: #E2EDED;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  color: $color-text;
  text-decoration: underline;
  text-decoration-color: #666;

  &:hover {
    opacity: 0.6;
  }

  @include view-at(pc) {
    &[href*="tel:"] {
      pointer-events: none;
      cursor: text;
      text-decoration: none;
    }
  }
}

p {
  margin-bottom: 2em;
  line-height: 2em;
  letter-spacing: 0.05em;

  @include view-at(pc) {
    font-size: rem(16px);
  }

  &:last-child {
    margin-bottom: 0;
  }
}

img {
  height: auto;
  max-width: 100%;
}

.en {
  font-family: futura-pt, "游ゴシック体", YuGothic, "Yu Gothic M", "游ゴシック Medium", "Yu Gothic Medium", "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN W3", HiraKakuProN-W3, "ヒラギノ角ゴ ProN", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, sans-serif;
  font-weight: 500;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-variant-ligatures: no-common-ligatures;
  -moz-font-feature-settings: "liga" 0, "clig" 0;
  -webkit-font-feature-settings: "liga" 0, "clig" 0;
  font-feature-settings: "liga" 0, "clig" 0;
}

.main {
}
