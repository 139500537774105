/* --------------------------
テーブル
-------------------------- */

.c-table {
  width: 100%;
  border-collapse: collapse;
  border: 1px solid $color-gray;

  thead {
    tr {
      border-bottom: 1px solid $color-gray;

      th {
        padding: 10px;
        background-color: $color-gray-snow;
      }
    }
  }

  tbody {
    tr {
      border-bottom: 1px solid $color-gray;
    }
    th {
      padding: 10px;
      word-break: keep-all;
    }

    td {
      padding: 10px;
    }
  }

  &--narrow {
    border-spacing: 0 25px;
  }

  &--border {
    th,
    td {
      border-right: 1px solid $color-gray;
    }

    th {
      background-color: $color-gray-snow;
    }
  }
}
